@import "../../../../assets/css/variables.scss";

.custom-check {
  @apply block relative pl-7 mb-3 cursor-pointer text-sm;

  &--disabled {
    @extend .custom-check;
    @apply cursor-default;
  }

  /* Hide the browser's default checkbox */
  input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
  }

  /* Create a custom checkbox */
  .checkmark {
    @apply absolute top-0 left-0 h-[18px] w-[18px] border border-solid border-txt rounded-2.7;

    &--rounded {
      @extend .checkmark;
      @apply rounded-20;

      &--disabled {
        @extend .checkmark--rounded;
        @apply border-grey cursor-default;
      }
    }

    &--disabled {
      @extend .checkmark;
      @apply border-grey cursor-default;
    }
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    @apply bg-[#2196f3] border border-solid border-secondary;
  }

  /* When the checkbox is disbaled, add a grey background */
  input:disabled:checked ~ .checkmark {
    @apply bg-[#9696A0] border border-solid;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    @apply absolute hidden;
    content: "";
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    @apply block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    @apply left-[5px] top-1 w-[7.2px] h-[6.3px] border border-solid border-white;
    border-width: 3px 3px 0px 0;
    transform: rotate(135deg);
  }

  &--secondary {
    @extend .custom-check;

    input:checked ~ .checkmark {
      @apply bg-dustblue border border-solid border-dustblue;
    }
  }
}
