@import '../../../assets/css/variables.scss';

.dashboard {
  @apply flex justify-center p-7 w-full h-full overflow-auto relative;

  &__header {
    @apply flex flex-col gap-5;
  }

  &__maintoolbar {
    @apply flex items-start md:items-baseline flex-col md:flex-row;
  }

  &__subtoolbar {
    @apply mt-2 flex items-center gap-2;

    .btn-subtool {
      @apply text-label text-sm font-semibold capitalize rounded-20 border border-solid border-label py-0.5 px-2.5;
      &.selected {
        @apply bg-label text-white;
      }
    }
  }
}
