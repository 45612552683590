@import "../../assets/css/variables.scss";

.layout {
  @apply flex flex-col h-screen w-full relative;

  &__header {
    @apply flex items-center h-[60px] bg-white px-5 shadow-customer;
  }

  &__content {
    @apply flex flex-1 overflow-hidden;

    &--customer {
      @extend .layout__content;
      @apply justify-center items-center bg-white md:bg-customer;
    }
  }

  &__bg {
    @apply absolute bottom-[50px] right-10 hidden md:block;
  }
}

.sidebar {
  @apply flex flex-col justify-between w-[282px] min-w-xs h-sidebar relative z-[9] transition-all pt-16;
  box-shadow: inset -8px 4px 8px -8px rgba(212, 223, 242, 0.5);
  background-color: $sidebarbg;

  &__menu {
    @apply w-full flex flex-col;

    &-item {
      @apply w-full min-h-12 hover:cursor-pointer relative pl-7 flex items-center text-base font-medium text-primary;

      &--beta {
        @apply rounded-full flex items-center justify-center bg-yellow text-10 font-semibold text-white w-[45px] h-[26px] ml-2;
      }

      &:hover {
        background-color: $lightDustBlue;
      }

      &.active {
        background-color: $lightDustBlue;
      }

      &.no-access {
        @apply opacity-30;
      }
    }

    &-label--secondary {
      @apply text-sm text-label;
    }
  }

  &__footer {
    @apply flex flex-col;
  }

  &__version-num {
    @apply ml-16 mb-3 text-xs text-label;
  }

  &--collapsed {
    @extend .sidebar;
    @apply w-75 transition-all min-w-0;

    span {
      @apply hidden;
    }
  }
}

.topbar {
  @apply flex h-[88px] px-7 py-2 justify-between items-center shadow-md z-10;

  &__section {
    @apply flex items-center justify-between;
    &--menu {
      @extend .topbar__section;
      @apply w-headerMenu md:w-auto;
    }

    &--tool {
      @extend .topbar__section;
      @apply w-headerTool md:w-auto;
    }

    &--auto {
      @extend .topbar__section--tool;
      @apply w-auto;
    }
  }
}

.customer-card {
  @apply relative flex justify-center pb-2 md:pt-5 bg-white h-full md:rounded-20 md:h-customer z-10 w-full md:w-customerLayout max-w-customerLayout;

  &__content {
    @apply flex justify-center md:w-[395px] w-full;
  }
}
