.tab {
  @apply w-full px-2 sm:px-0 h-full flex flex-col;
  &__list {
    @apply flex border-b-2 border-waikawaGrey overflow-x-scroll;

    &__title {
      @apply bg-sidebarbg rounded-t-lg py-2.5 px-6 text-xs font-bold whitespace-nowrap text-label hover:bg-waikawaGrey hover:text-white;

      &--selected {
        @extend .tab__list__title;
        @apply bg-waikawaGrey text-white;
      }
    }
  }

  &__panels {
    @apply mt-2 flex-1;
  }

  &__panel {
    @apply rounded-xl h-full flex flex-col;

    &__edit {
      @apply w-86;
      &__title {
        @apply uppercase font-bold text-sm text-white;
      }
    }
  }
}
