.card-vehicle {
  @apply w-full flex flex-col sm:flex-row lg:h-44 hover:cursor-pointer gap-3;

  &__img {
    @apply relative sm:flex-1 rounded-12 h-36 sm:h-full overflow-hidden;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__status {
    @apply absolute top-4 right-4;
  }

  &__valued {
    @apply absolute bottom-0 left-0;
  }

  &__info {
    @apply flex flex-col lg:flex-row sm:flex-1 lg:flex-2;
  }

  &__section {
    @apply flex-1 flex flex-col p-3 gap-2 w-full justify-center;

    &--customer {
      @apply flex-1 flex items-baseline gap-2;
    }
  }

  &__txt {
    &--title {
      @apply font-semibold text-darktxt leading-5 truncate max-w-sm;

      &--seller {
        @apply max-w-2xl pt-2 truncate whitespace-normal line-clamp-2;
      }

      &--buyer {
        @apply max-w-430 truncate whitespace-normal line-clamp-2;
      }

      &--name {
        @apply truncate whitespace-normal line-clamp-2
      }
    }

    &--info {
      @apply text-sm text-darktxt;

      b {
        @apply font-semibold;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &--desc {
      @apply text-sm leading-4 text-label line-clamp-2;

      &.no-overflow{
        overflow: unset
      }
    }
  }
}

.tender-card-vehicle {
  @media (max-width: 1590px) {
    @apply h-auto;

    .tender-card-vehicle__info {
      @apply flex-none flex-col
    }
  }
}

.sell-button {
  @apply w-28 mt-8;

  button {
    @apply py-0;
  }
}