@import "../../../assets/css/variables.scss";

.primary-button {
  @apply rounded-full text-white w-full py-2 text-sm;
  background-color: $primary;
  &:focus-visible {
    outline: 0;
  }

  &--disabled {
    @extend .primary-button;
    @apply bg-label;
  }

  .loading {
    @apply flex items-center justify-center;
  }
}

.secondary-button {
  @apply rounded-full text-primary w-full py-2 text-sm bg-white shadow-md;

  &--disabled {
    @extend .secondary-button;
    @apply text-label border-label;
  }
}

.link-button {
  @apply text-primary text-sm cursor-pointer font-medium;
}
