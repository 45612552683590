.pagination {
  @apply flex items-center;

  &__title {
    @apply text-base text-label whitespace-nowrap mr-9;
  }

  &__numbers {
    @apply px-2 text-base;

    span {
      @apply hover:cursor-pointer font-light px-2 hover:bg-[#EFEFEF] hover:rounded-full;
    }

    .active {
      @apply font-bold;
    }
  }
}
