@import "../../../../assets/css/variables.scss";

.input {
  @apply h-10 w-full rounded-4 border-solid border-white border pl-3 text-txt font-normal text-sm leading-[150%] focus:outline-none;
  background-color: $inputbg;

  &-wrapper {
    @apply w-full flex flex-col;
  }

  &-label {
    @apply text-sm text-txt font-medium;
  }

  &-required {
    background-color: $lightRed;
  }
}

.textarea {
  @apply w-full rounded-4 border-solid border-white border px-3 py-[8.5px] text-txt font-light text-sm leading-[150%] focus:outline-none;
  background-color: $inputbg;

  &-wrapper {
    @apply w-full flex flex-col;
  }

  &-label {
    @apply text-sm text-txt font-medium;
  }

  &:focus::placeholder {
    color: transparent;
  }
}