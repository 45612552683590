@import "../../../assets/css/variables.scss";

/* Tooltip container */
.tooltip {
  position: relative;
  display: block;

  /* Tooltip text */
  .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: $txt;
    color: #fff;
    text-align: center;
    padding: 3px 5px;
    border-radius: 6px;
    font-size: 12px;
    white-space: pre;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
