// Import all `Poppins` fonts since it's the main font.
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import "./variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Import Swiper styles
@import "swiper/scss";
@import "swiper/scss/zoom";
@import "swiper/scss/lazy";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.badge {
  @apply rounded-full py-1 px-2;
}

.disclosure__btn {
  @apply uppercase text-sm text-txt;

  &:hover {
    background-color: transparent;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply font-semibold text-lg flex-row-reverse p-4;
  background-color: $redVibrant;

  .Toastify__toast-body {
    @apply pl-4;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply font-semibold text-lg flex-row-reverse p-4;
  background-color: $greenVibrant;

  .Toastify__toast-body {
    @apply pl-4;
  }
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slick-slide > div {
  margin: auto;
  max-width: 800px !important;
  height: calc(100vh - 200px) !important;
  display: flex;
  align-items: center;

  figure {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slick-dots {
  bottom: -50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  li {
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.slick-next {
  right: 10px !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-arrow {
  width: 50px !important;
  height: 50px !important;
  z-index: 1;
}

.slick-arrow:before {
  font-size: 50px !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
