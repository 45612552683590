@import '../../../../assets/css/variables.scss';

.dropdown {
  @apply text-sm w-[300px];

  &--disabled {
    @extend .dropdown;
    .dropdown__input {
      @apply opacity-50;
    }
  }

  .wrapper {
    @apply relative;
  }

  .input-container {
    @apply relative w-full;
  }

  &-label {
    @apply text-sm text-label;
  }

  &__input {
    @apply h-10 w-full text-txt pl-3 pr-10 focus:outline-none;
    background-color: $sidebarbg;

    &__icon {
      @apply absolute top-[17px] right-[14px] hover:cursor-pointer;
    }
  }

  &__options {
    @apply absolute w-full z-10 py-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 sm:text-sm;
    background-color: $white;

    &__nothing {
      @apply cursor-default select-none relative py-2 px-4;
    }
  }

  &__search {
    @apply relative flex items-center pl-5;

    &__input {
      @apply h-10 w-full pl-3 focus:outline-none;
    }
  }

  &__new {
    @apply text-secondary text-sm py-3 pr-10 pl-5 hover:cursor-pointer;
  }

  &__option {
    @apply cursor-default select-none relative pt-3 pb-3 pr-10 pl-5 hover:cursor-pointer;

    &__title {
      @apply h-7 text-primary block truncate;
    }

    &__icon {
      @apply absolute inset-y-0 right-0 flex items-center pr-4;
    }
  }
}
