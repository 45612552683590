.customer {
  @apply flex flex-col justify-between text-primary w-full;

  &__stepper {
    @apply absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach;

    &__content {
      @apply bg-primary h-[7px] mt-0 md:mt-33;
    }
  }

  &__content {
    @apply flex-1 w-full flex flex-col overflow-auto px-5;

    &--welcome {
      @extend .customer__content;
    }

    &--complete {
      @extend .customer__content;
      @apply mt-complete;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      /* Foreground */
      background: #d3d3d3;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      /* Background */
      background: #fff;
    }
  }

  &__bottom-bar {
    @apply py-3 px-8 flex flex-col gap-4;
  }

  &__logo {
    @apply h-150;
  }

  label {
    @apply font-medium text-base mb-5;
  }

  .input-label,
  .dropdown-label {
    @apply text-sm text-txt mb-1 font-medium;
  }

  &__tool {
    @apply w-310 mx-auto;

    &__title {
      @apply uppercase text-white text-sm font-bold;

      &--secondary {
        @extend .customer__tool__title;
        @apply text-darkKnight;
      }
    }

    &--secondary {
      @extend .customer__tool;

      button {
        @apply bg-white border border-solid border-whiteWhale;
      }
    }
  }
}

.welcome {
  &__label {
    @apply text-primary font-normal text-base;
  }

  &__title {
    @apply font-bold text-2xl mb-9 mt-147 md:mt-158;
  }

  &__name {
    @apply font-medium text-lg;
  }

  &__card {
    @apply font-medium text-sm mt-7;
    span {
      @apply text-academicBlue mb-1;
    }

    p {
      @apply text-label font-normal;
    }
  }
}

.vehicle {
  &__title {
    @apply font-bold text-2xl mb-5 mt-95;
  }

  &__card {
    @apply font-medium text-sm mt-7;

    p {
      @apply text-label font-normal;
    }

    .vin-label {
      @apply text-sm text-label mb-1;
    }

    .btn-vin button {
      @apply text-label font-normal underline;
    }

    .btn-option {
      @apply text-darkKnight font-semibold text-sm block;
      button {
        @apply underline;
      }
    }
  }
}

.vehicle-confirmation {
  &__title {
    @apply font-bold text-2xl mb-5 mt-60;
  }

  &__logo {
    @apply mx-auto mt-18;
  }

  &__name {
    @apply font-semibold text-base text-txt mt-9;
  }

  &__content {
    .state {
      @apply py-4 border-solid border-softPeach border-b h-auto;

      &__value {
        @apply font-medium text-black;
      }

      &__name {
        @apply font-normal;
      }
    }
  }

  &__tip {
    @apply text-label text-sm mt-4 mb-6;
  }
}

.enter-kms {
  &__back {
    @apply text-black text-sm pt-5;
    button {
      @apply underline;
    }
  }

  &__title {
    @apply font-bold text-2xl mb-10 mt-5;
  }

  &__logo {
    @apply mx-auto mb-52;
  }

  &__content {
    @apply relative;

    &__unit {
      @apply absolute right-2.5 top-34;
    }
  }

  &__desc {
    @apply text-label text-sm mt-8;
  }
}

.view-specification,
.view-compliance,
.take-picture {
  &__back {
    @apply text-black text-sm pt-7;
    button {
      @apply underline;
    }
  }

  &__title {
    @apply text-primary font-bold text-23 mt-4 mb-0;
  }

  &__step {
    label {
      @apply text-primary text-sm font-bold leading-10;
    }

    p {
      @apply text-label text-sm font-normal;
    }
  }

  &__comment {
    @apply mt-8 mb-5;
    .textarea-label {
      @apply text-lightGrey text-sm font-medium mb-small;
    }
  }

  &__banner {
    @apply absolute bg-lightDustBlue -left-5 -right-5 -top-40 md:-top-48 h-54 px-5 py-3 flex items-center justify-center text-primary font-bold text-lg shadow-banner rounded-br-20 rounded-bl-20;
  }

  &__content {
    @apply relative flex flex-col;
    .state {
      @apply py-4 border-solid border-softPeach border-b h-auto;

      &__value {
        @apply font-medium text-black;
      }

      &__name {
        @apply font-normal;
      }
    }

    .textarea-label {
      @apply text-lightGrey;
    }

    label {
      @apply mb-0;
    }

    &__title {
      @apply text-lightGrey text-sm font-medium mb-0;
    }

    &__desc {
      @apply text-txt text-lg;
    }

    &__photos {
      @apply grid grid-cols-2 gap-4;

      .photo-item {
        @apply w-162 h-162 flex items-center justify-center bg-lighterBlue rounded border border-lightDustBlue border-solid hover:cursor-pointer;

        &--empty {
          @extend .photo-item;
          @apply text-center;

          span {
            @apply text-waikawaGrey text-sm mt-3;
          }
        }

        &__content {
          @apply relative flex items-center justify-center flex-col;

          &__delete {
            @apply absolute right-3 bottom-3 z-10;
          }

          &__img {
            @apply w-162 h-162 object-cover;
          }
        }
        &--upload {
          @apply hidden;
        }
      }
    }
  }

  &__desc {
    @apply text-label text-sm mt-8;

    button {
      @apply text-label text-sm underline;
    }
  }

  &__footer {
    @apply w-[310px] pb-5;
  }
}

.complete {
  &__title {
    @apply font-semibold text-darkKnight text-2xl my-9;
  }

  &__desc {
    @apply text-label text-sm font-medium mt-8;

    span {
      @apply text-primary;
    }
  }

  &__footer {
    @apply text-black text-sm font-medium absolute bottom-[76px] w-full;
  }
}

.option__add {
  @apply flex gap-2;

  &__title {
    @apply font-bold;
  }
}
