@import '../../../assets/css/variables.scss';

.information {
  @apply rounded-xl w-full flex flex-col gap-4;

  .input-label,
  .textarea-label {
    @apply text-txt font-medium;
  }

  &__title {
    @apply text-txt font-semibold text-base;
  }

  &__header {
    @apply w-full grid grid-cols-1 md:grid-cols-2 items-center gap-y-6 gap-x-10;
  }

  &__comments {
    @apply w-full mb-5;
  }

  &__tool {
    @apply w-full flex items-center mt-1 mb-4;

    .btn__send {
      @apply w-114;

      button {
        @apply bg-white shadow-md;

        span {
          @apply uppercase text-lightGrey text-sm font-bold;
        }
      }
    }

    .btn__save {
      @apply w-90 ml-2;

      button {
        @apply shadow-md;
        span {
          @apply uppercase text-sm font-bold;
        }
      }
    }
  }

  .comment {
    @apply rounded-10 shadow-carousel px-4 py-3 text-lightGrey mb-4;

    &__header {
      @apply flex items-center;

      span {
        @apply font-medium text-sm mr-11;
      }

      label {
        @apply font-normal text-xs mb-0;
      }
    }

    &__role {
      @apply font-normal text-xs mb-0;
    }

    p {
      @apply text-txt font-light text-sm py-2;
    }
  }
}
