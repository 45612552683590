$primary: #0c2146;
$label: #9696a0;
$txt: #383b3f;
$darktxt: #222b45;
$lighttxt: #edf1f7;
$red: #c8372d;
$redVibrant: #f6655a;
$lightRed: #ffe7e5;
$greenVibrant: #3fb960;
$white: #ffffff;
$black: #000000;
$grey: #e4e9f2;
$lightGrey: #3a3a44;
$inputbg: #f7f9fc;
$sidebarbg: #f6f7f9;
$lightDustBlue: #d4dff2;
$yellow: #ff862f;
$lightBlue: #787885;
$dustblue: #8f9bb3;
