@import "../../../assets/css/variables.scss";

.dashboard {
  @apply flex justify-center p-7 w-full h-full overflow-auto relative;

  &__header {
    @apply flex flex-col gap-5;
  }

  &__title {
    @apply text-primary font-semibold text-2xl;
  }

  &__toolbar {
    @apply flex items-start md:items-center flex-col md:flex-row;
  }

  &__content {
    &--empty {
      @apply py-16 text-label text-lg;
    }

    &--error {
      @apply py-16 text-primary text-lg;
    }

    &__select {
      @apply text-abaddonBlack text-sm flex items-center justify-end mr-15;
    }

    &__header {
      @apply bg-sidebarbg uppercase text-slateGray text-xs font-semibold rounded-t-10 p-[18px] grid grid-rows-1 grid-cols-pool gap-4 items-center;

      div {
        @apply text-center;
        span {
          @apply block text-10;
        }
      }
    }

    &__tool {
      @apply mt-10 flex justify-end;

      button {
        @apply w-160;
      }

      &__txt {
        @apply uppercase text-white text-sm font-bold;
      }
    }
  }

  &__footer {
    @apply py-6;
  }

  .pool-control {
    @apply bg-white text-cornflowerBlue text-sm font-normal px-5 py-[14px] grid grid-rows-1 grid-cols-pool items-center gap-4 border border-solid border-crystalBell;
  }
}
