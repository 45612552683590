.vehicle-search {
    .variant {
        @apply border-b-2 border-[#EDF1F7] pl-[80px]
    }
}

.dropdown {
    @apply w-full mb-5
}

.dropdown-label {
    @apply text-primary
}