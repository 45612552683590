@import "../../../assets/css/variables.scss";

.dashboard {
  @apply flex justify-center p-7 w-full h-full overflow-auto relative;

  &__container {
    @apply flex flex-col max-w-1300 w-full;
  }

  &__header {
    @apply flex flex-col gap-5;
  }

  &__toolbar {
    @apply flex items-start md:items-center flex-col md:flex-row;
  }

  &__content {
    @apply flex w-full py-5 gap-10;

    &--left {
      @apply flex flex-col flex-1 gap-10 overflow-auto min-w-840;
    }

    &--right {
      @apply flex flex-col gap-10 min-w-430;

      .right-container {
        @apply flex flex-col gap-10 w-430;
      }
    }

    &--empty {
      @apply py-16 text-label text-lg;
    }
  }

  &__footer {
    @apply py-6;
  }
}
