.dashboard {
  @apply flex justify-center p-7 w-full h-full overflow-auto relative;

  &__header {
    @apply flex flex-col gap-5;
  }

  &__content {
    .profile__content {
      @apply grid grid-cols-1 items-start md:grid-cols-2 gap-8;

      &__info {
        @apply grid grid-cols-1 gap-6;

        .input-label {
          @apply text-txt font-medium;
        }
      }

      &__other {
        @apply grid grid-cols-1 gap-4 max-w-[330px] mt-4;

        &__notification {
          @apply grid grid-cols-1 gap-2;
        }

        .profile__password {
          @apply flex items-center justify-between;
        }

        .profile__form {
          @apply relative w-full flex flex-col mt-2.5;

          &__icon {
            @apply absolute right-4 top-8 z-10 hover:cursor-pointer;
          }
        }
      }
    }

    .profile__warning {
      @apply w-450 p-3 bg-lightGold text-sm text-darkGold200 rounded-10;
    }

    &__tool {
      @apply justify-start w-86;
    }
  }
}
