@import "../../../assets/css/variables.scss";

.modal {
  @apply fixed inset-0 z-10 overflow-y-auto;

  &__container {
    @apply inline-block my-8 text-left align-middle transition-all transform shadow-xl rounded-20;
    background-color: $white;
  }

  &__overlay {
    @apply fixed inset-0 opacity-50;
    background-color: $black;
  }

  &__header {
    @apply relative flex items-center justify-center pt-12;

    &.with-title {
      @apply pt-10 pb-4;
    }

    &--title {
      @apply text-xl font-bold text-darktxt;
    }

    &--close {
      @apply absolute top-5 right-5;
      &:focus-visible {
        outline: 0;
      }
    }
  }

  &__content {
    @apply px-7 md:px-12 pb-10;
  }

  .w-responsive {
    @apply min-w-sm md:min-w-md;
  }
}
