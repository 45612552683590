.disclosure {
  &--disabled {
    span {
      @apply text-label;
    }
  }

  &-header {
    @apply w-full flex justify-between items-center border-b border-softPeach outline-0;

    &--left {
      @apply flex items-center text-lightGrey text-sm font-medium leading-10;
    }

    &__img {
      @apply hover:cursor-pointer;
    }
  }

  &__pannel {
    @apply w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-8 pb-5 mt-4;

    &__section {
      @apply flex flex-col gap-6;
    }

    &__placeholder {
      @apply m-5 text-label text-sm;
    }
    &--secondary {
      @apply w-full flex flex-col gap-y-3 pb-5 mt-4;

      .state__name {
        @apply font-normal;
      }
    }

    &--wide {
      @extend .disclosure__pannel;
      @apply lg:grid-cols-2;

      .state__name {
        @apply font-semibold min-w-144 flex-none;
      }

      .state__value {
        @apply flex-1;
      }

      .state--comment {
        grid-row: 10;
        grid-column: 1 / 3;

        @media (max-width: 746px) {
          grid-row: 18;
          grid-column: 1;
        }
      }

      &--feature {
        @extend .disclosure__pannel;
        @apply grid-cols-1;

        .state__name {
          @apply font-semibold min-w-sm flex-none;
        }
      }

      .valuation-field,
      .valuation-field--request {
        @apply gap-0 border-none;
      }
    }
  }
}
