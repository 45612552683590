@import '../../../assets/css/variables.scss';

.statusbar {
  @apply rounded-3xl flex items-center overflow-auto md:flex-nowrap gap-y-5 md:gap-y-0 w-full sm:w-fit overflow-x-clip h-7;
  background-color: $sidebarbg;
}

.fixed__status__width {
  .statusbar {
    > * {
      @apply w-100 text-center
    }
  }
}