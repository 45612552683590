@import '../../assets/css/variables.scss';

.auth-container {
  @apply flex h-screen justify-center items-center;
  background: url(../../assets/images/bg-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.auth-card {
  &__content {
    @apply px-14 pt-12 pb-14 flex flex-col gap-y-7;
  }

  &__title {
    @apply text-lightGrey text-3xl font-bold;
  }

  &__desc {
    @apply text-lightGrey text-sm font-medium;
  }

  &__form {
    @apply relative w-full flex flex-col;

    .input-wrapper {
      @apply border border-solid border-lightDustBlue;
    }

    &__icon {
      @apply absolute right-4 top-3 z-10 hover:cursor-pointer;
    }
  }

  &__footer {
    @apply flex justify-between items-center;
  }
}
