@import '../../../../assets/css/variables.scss';

.radio {
  @apply flex items-center;

  &__input {
    @apply h-4 w-4 border rounded-sm  focus:outline-none transition duration-200 align-top float-left mr-2 cursor-pointer;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__label {
    @apply inline-flex items-center cursor-pointer;
  }
}
