.banner {
  @apply rounded-10 px-2 py-3.5 font-semibold text-xl text-center capitalize;

  &--draft {
    @extend .banner;
    @apply text-white bg-dustblue;
  }

  &--review,
  &--declined-valuation,
  &--lost,
  &--lost-sale,
  &--rejected,
  &--closed,
  &--revoked,
  &--declined,
  &--verification-declined {
    @extend .banner;
    @apply text-redVibrant bg-lightRed;
  }

  &--awaiting,
  &--negotiating,
  &--tendered,
  &--scheduled,
  &--pending,
  &--pending-sale,
  &--in-auction,
  &--my-bids {
    @extend .banner;
    @apply text-darkGold bg-lightGold;
  }

  &--acquired,
  &--buy-now,
  &--withdrawn-buyer {
    @extend .banner;
    @apply text-academicBlue bg-brilliantWhite;
  }

  &--sold-to-retail,
  &--accepted,
  &--sold-to-mp,
  &--sold-to-dealership,
  &--active,
  &--sold-tendered,
  &--sold-direct-offer,
  &--won,
  &--sold-auction,
  &--pending-verification,
  &--verification-approved {
    @extend .banner;
    @apply text-darkGreen bg-lightGreen;
  }
}